import React from "react"

import Tile from "@kiwicom/orbit-components/lib/Tile"
import TileGroup from "@kiwicom/orbit-components/lib/TileGroup"

import Container from "components/container"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Tachograph">
    <Hero title="Tachograph and Driver's Hours" />
    <Container size="medium">
      <TileGroup>
        <Tile
          title="EU Tachograph & Drivers' Hours Rules"
          description="Learn about the rules that apply when driving any Ember service"
          href="rules/"
        />
        <Tile
          title="Tachograph Infringements"
          description="Checking for infringements and acting on them"
          href="infringements/"
        />
        <Tile
          title="Using a Tachograph Correctly"
          description="How to use the VDO Digital Tachographs fitted to our coaches"
          href="tachograph-guide/"
        />
      </TileGroup>
    </Container>
  </DriveLayout>
)

export default Page
